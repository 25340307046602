<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div class="animated fadeIn">
      <b-form @submit.prevent="onSubmit" novalidate>
        <b-card-header>
          Create Add On
          <div class="card-header-actions">
            <a
              class="card-header-action"
              href="addons"
              rel="noreferrer noopener"
            >
              <small class="text-muted">Back</small>
            </a>
          </div>
        </b-card-header>
        <b-card>
          <b-row class="mb-4">
            <b-col class="mb-4" sm="12">
              <h6>Code <span class="text-red">*</span></h6>
              <b-form-group id="userNameInputGroup3" label-for="code">
                <template #description>
                  <span
                    :class="{
                      'text-danger':
                        !warning_message || !$v.form.code.validateCode
                    }"
                  >
                    {{
                      warning_message
                        ? "The code consists of lowercase letters and hyphens (-). Minimum 4 characters, maximum 10 characters. Symbols and numbers are not allowed."
                        : "The code you entered has already been used. Please enter another code."
                    }}
                  </span>
                </template>
                <b-form-input
                  id="code"
                  type="text"
                  size="md"
                  v-model="form.code"
                  aria-describedby="codeFeedback"
                  placeholder="Code Add On"
                  autocomplete="given-name"
                  :state="chkState('code')"
                  @change="isUnique"
                >
                </b-form-input>
                <!-- <b-form-invalid-feedback id="codeFeedback">
                  <span v-if="!$v.form.code.required">- Code tidak boleh kosong! </span>
                  <span v-if="!$v.form.code.validateCode">- Code yang dimasukkan tidak valid!</span>
                </b-form-invalid-feedback> -->
              </b-form-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>Add On Name<span class="text-red">*</span></h6>
              <b-form-input
                id="name"
                type="text"
                size="md"
                v-model="form.name"
                aria-describedby="nameFeedback"
                placeholder="Add On Name"
                autocomplete="given-name"
                :state="chkState('name')"
                autofocus
              ></b-form-input>
              <!-- <b-form-invalid-feedback id="nameFeedback">
                <span v-if="!$v.form.name.required">- Add On Name tidak boleh kosong! </span>
              </b-form-invalid-feedback> -->
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>Description <span class="text-red">*</span></h6>
              <b-form-input
                id="description"
                type="text"
                size="md"
                v-model="form.description"
                aria-describedby="descFeedback"
                placeholder="Description"
                autocomplete="given-name"
                :state="chkState('description')"
                autofocus
              ></b-form-input>
              <!-- <b-form-invalid-feedback id="descFeedback">
                <span>- Description tidak boleh kosong! </span>
              </b-form-invalid-feedback> -->
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6 class="mb-1">Add On Type <span class="text-red">*</span></h6>
              <b-form-group class="mt-3">
                <b-form-radio-group
                  id="addon-type"
                  :state="chkState('addonType')"
                  v-model="form.addonType"
                  name="addon-type"
                >
                  <b-form-radio value="Transaction Fee">Bundling</b-form-radio>
                  <b-form-radio value="Gold For Good"
                    >Gold For Good</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col
              class="mb-4"
              sm="12"
              v-if="form.addonType === 'Transaction Fee'"
            >
              <h6>Max Bundle <span class="text-red">*</span></h6>
              <b-input-group>
                <b-form-input
                  id="MaxBundle"
                  type="number"
                  size="md"
                  v-model="form.maxBundle"
                  aria-describedby="reasonFeedback"
                  placeholder="Max Bundle"
                  autocomplete="given-name"
                  autofocus
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col
              class="mb-4"
              sm="12"
              v-if="form.addonType === 'Gold For Good'"
            >
              <h6>Priority Ordering <span class="text-red">*</span></h6>
              <b-input-group>
                <b-form-select v-model="form.priorityOrdering">
                  <option value="">Choose Priority Ordering</option>
                  <option v-for="index in 10" :key="index" :value="index">{{
                    index
                  }}</option>
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>Data Type <span class="text-red">*</span></h6>
              <b-form-group class="mb-0" id="dataType">
                <b-form-select
                  v-model="form.dataType"
                  :state="chkState('dataType')"
                  :options="[
                    { text: 'Choose Data Type', value: '' },
                    { text: 'Fixed', value: 'Fixed' },
                    { text: 'Percentage', value: 'Percentage' },
                    { text: 'Multiply', value: 'Multiply' }
                  ]"
                ></b-form-select>
                <!-- <b-form-invalid-feedback>
                  <span v-if="!$v.form.dataType.required">- Data Type tidak boleh kosong! </span>
                </b-form-invalid-feedback> -->
              </b-form-group>
            </b-col>
            <b-col class="mb-4" sm="12" v-if="form.dataType">
              <h6>
                Input Amount ({{
                  form.dataType === "Percentage" ? "%" : "IDR"
                }})
                <span class="text-red">*</span>
              </h6>
              <b-input-group>
                <b-form-input
                  id="inputAmount"
                  type="number"
                  size="md"
                  v-model="form.amount"
                  aria-describedby="reasonFeedback"
                  placeholder="Input Amount"
                  autocomplete="given-name"
                  autofocus
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col class="mb-3" sm="12">
              <h6 class="mb-1">Feature <span class="text-red">*</span></h6>
              <b-form-group label-for="basicInlineCheckboxess">
                <b-form-checkbox-group
                  id="basicInlineCheckboxess"
                  name="InlineCheckboxes"
                  :plain="true"
                  v-model="form.feature_id"
                >
                  <b-form-checkbox :plain="true" :value="true"
                    >Beli Emas</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>Add on Period <span class="text-muted">(Optional)</span></h6>
              <b-form-group class="mb-3" label-for="daterange">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <i class="fa fa-calendar"></i>
                  </b-input-group-prepend>
                  <range-picker
                    id="daterange"
                    :start="startDate"
                    :end="endDate"
                    :type="'clear'"
                    :disable="false"
                    :autoUpdate="false"
                    :timePicker="true"
                    @picker="changePicker"
                  >
                  </range-picker>
                  <b-input-group-append>
                    <b-button
                      variant="secondary"
                      @click="resetDate"
                      type="button"
                    >
                      Reset
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6 class="mb-1">Tax Type <span class="text-red">*</span></h6>
              <b-form-group class="mt-3">
                <b-form-radio-group
                  id="tax-type"
                  v-model="form.taxType"
                  name="tax-type"
                >
                  <b-form-radio value="Nett">Nett</b-form-radio>
                  <b-form-radio value="Gross">Gross</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col class="mb-4" sm="12" v-if="form.taxType">
              <h6>Value Tax(%) <span class="text-muted">(Optional)</span></h6>
              <b-input-group>
                <b-form-input id="tax" type="number" v-model="form.tax" />
              </b-input-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>
                Discount Amount (IDR) <span class="text-muted">(Optional)</span>
              </h6>
              <b-input-group>
                <b-form-input
                  id="discount"
                  type="number"
                  v-model="form.discount"
                />
              </b-input-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>
                URL Logo Add Ons <span class="text-muted">(Optional)</span>
              </h6>
              <b-input-group>
                <b-form-input
                  id="MaxBundle"
                  type="text"
                  size="md"
                  v-model="form.urlLogoAddons"
                  aria-describedby="urlFeedback"
                  placeholder="URL Logo Add Ons"
                  autocomplete="given-name"
                  autofocus
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6>
                URL Detail Add Ons <span class="text-muted">(Optional)</span>
              </h6>
              <b-input-group>
                <b-form-input
                  id="discount"
                  type="text"
                  :value="parseValue(data.urlDetailAddons)"
                />
              </b-input-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6 class="mb-1">Continuity <span class="text-red">*</span></h6>
              <b-form-group class="mt-3">
                <b-form-radio-group
                  id="continuity"
                  v-model="form.continuity"
                  name="continuity"
                >
                  <b-form-radio :value="true">Yes</b-form-radio>
                  <b-form-radio :value="false">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col class="mb-4" sm="12">
              <h6 class="mb-1">
                Add On Status <span class="text-red">*</span>
              </h6>
              <b-form-group class="mt-3">
                <b-form-radio-group
                  id="addon-status"
                  v-model="form.addonStatus"
                  name="addon-status"
                >
                  <b-form-radio :value="true">Active</b-form-radio>
                  <b-form-radio :value="false">Inactive</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            type="submit"
            variant="primary"
            :disabled="!this.$v.$invalid && this.warning_message ? false : true"
          >
            Submit
          </b-button>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
const validateCode = helpers.regex("validateCode", /^[a-zA-Z-]+$/);

export default {
  name: "create-addons",
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      code: {
        required,
        validateCode
      },
      name: {
        required
      },
      description: {
        required
      },
      addonType: {
        required
      },
      dataType: {
        required
      },
      feature_id: {
        isCheckedTrue(value) {
          return value === true;
        }
      },
      taxType: {
        required
      },
      continuity: {
        required
      },
      addonStatus: {
        required
      }
    }
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    }
  },
  data() {
    return {
      data: {},
      startDate: "",
      endDate: "",
      form: {
        code: "",
        name: "",
        description: "",
        addonType: "",
        dataType: "",
        taxType: "",
        discount: 0,
        amount: 0,
        maxBundle: 0,
        urlLogoAddons: "",
        urlDetailAddons: "",
        continuity: "",
        addonStatus: "",
        priorityOrdering: "",
        tax: 0,
        feature_id: false
      },
      isLoading: false,
      warning_message: true,
      errors: {
        code: "",
        message: "",
        status: ""
      }
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  },
  methods: {
    changePicker(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    getIdValue(val, data) {
      if (val && data.length > 0) {
        const find = data.filter(v => {
          return v.text === val;
        });

        if (find.length > 0) return find[0].value;
      } else {
        return null;
      }
    },
    isUnique(value) {
      if (value === "") return true;
      this.$v.form.code.$touch();
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/addons/setup/check-code`, { code: value })
          .then(res => {
            if (res.data.meta.code === 200) {
              this.warning_message = true;
            }
            resolve(res);
          })
          .catch(error => {
            if (error.response) {
              this.warning_message = false;
              reject(error);
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          });
      });
    },
    parseValue(event) {
      return event ? event : "-";
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.warning_message) {
        this.$swal.fire(
          "Failed!",
          `Code yang dimasukkan telah dipakai.`,
          "error"
        );
      }
      if (!this.$v.$invalid && this.warning_message) {
        const requestBody = {
          code: this.form.code,
          name: this.form.name,
          description: this.form.description,
          add_on_type: this.form.addonType,
          priority: this.form.priorityOrdering,
          max_bundle: this.form.maxBundle,
          data_type: this.form.dataType,
          amount: this.form.amount,
          feature_id: this.form.feature_id,
          period_from: this.startDate === "" ? null : this.startDate,
          period_to: this.endDate === "" ? null : this.endDate,
          tax_type: this.form.taxType,
          tax: this.form.tax,
          discount: this.form.discount,
          url_logo: this.form.urlLogoAddons,
          url_detail: this.form.urlDetailAddons,
          is_continuity: this.form.continuity,
          is_active: this.form.addonStatus
        };

        this.isLoading = true;
        this.$http
          .post(`addons/setup/create`, requestBody)
          .then(res => {
            this.isLoading = false;
            this.$swal
              .fire("Success!", `${res.data.meta.message}`, "success")
              .then(() => {
                this.$router.push({ name: "Add Ons Setup" });
              });
          })
          .catch(error => {
            if (error.response) {
              this.isLoading = false;
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
              if (this.errors.status === 404) {
                this.$swal
                  .fire("Failed!", `${res.data.meta.message}`, "error")
                  .then(() => {
                    // location.reload()
                  });
              }

              if (this.errors.status === 400) {
                this.$swal.fire("Failed!", this.errors.message, "error");
              }
            }
          });
      }
    },
    resetDate() {
      this.startDate = "";
      this.endDate = "";
      $('input[name="daterange"]').val("");
    }
  }
};
</script>

<style>
.submt {
  width: 100%;
  height: auto;
  display: block;
}
</style>
<style>
.btn.disabled {
  background: #dfdfdf !important;
  color: grey !important;
  border-color: #dfdfdf !important;
}

.form-control {
  height: 36.22px;
}

.text-red {
  color: #cb211f;
}
.text-success {
  color: #35aa4b;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
